import { defineStore } from "pinia";
import { usePwaStore } from "@/stores/pwa";
import { useAuthStore } from "@/stores/auth";
import axios from "@/plugins/axios";
import type { Site } from "@/types/site";

/**
 * A store module for managing the Site resource.
 *
 * In the following, when interacting with the API, errors are handled by:
 * 1. setting requireSync to true to recover via syncing
 * 2. rethrow the error for console output and for BugSnag
 */
export const useSiteStore = defineStore("site", () => {
	/**
	 * Store a client by calling the API client store endpoint.
	 */
	const store = async function (site: Site): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		site.created_at = isoString;

		// Update the current user store
		for (const client of authStore.user.clients) {
			const estate = client.estates.find((x: any) => x.id == site.estate_id);
			if (estate) {
				estate.sites.push(site);
				break;
			}
		}

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.post(route("api.site.store"), site).then(()=>{
				console.log("added new site");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Update a site by calling the API site update endpoint.
	 */
	const update = async function (site: Site): Promise<void> {
		const pwaStore = usePwaStore();
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		site.updated_at = isoString;

		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.patch(route("api.site.update", { id: site.id }), site).then(() => {
				console.log("site updated");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Destroy a site by calling the API site destroy endpoint.
	 */
	const destroy = async function (site: Site): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		// Mark the site as deleted
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		site.deleted_at = isoString;
		site.updated_at = isoString;

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.delete(route("api.site.destroy", { id: site.id })).then(() => {
				console.log("deleted site");
				// Remove local soft-deleted sites by refetching data
				authStore.getCurrentUser();
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	return { store, update, destroy };
}, {
	persist: {
		// Ensure none of this store is persisted. Clients are persisted via the
		// current user store.
		paths: []
	}
});

export const countiesList = ["Aberdeenshire",
	"Anglesey",
	"Angus",
	"Antrim",
	"Argyll (Argyllshire)",
	"Armagh",
	"Ayrshire",
	"Banffshire",
	"Bedfordshire",
	"Berkshire",
	"Berwickshire",
	"Brecknockshire",
	"Buckinghamshire",
	"Buteshire",
	"Caernarfonshire",
	"Caithness",
	"Cambridgeshire",
	"Cardiganshire",
	"Carmarthenshire",
	"Cheshire",
	"Clackmannanshire",
	"Cornwall",
	"Cromartyshire",
	"Cumberland",
	"Denbighshire",
	"Derbyshire",
	"Devon",
	"Dorset",
	"Down",
	"Dumbartonshire",
	"Dumfriesshire",
	"Durham",
	"East Lothian",
	"Essex",
	"Fermanagh",
	"Fife",
	"Flintshire",
	"Glamorgan",
	"Gloucestershire",
	"Hampshire",
	"Herefordshire",
	"Hertfordshire",
	"Huntingdonshire",
	"Inverness-shire",
	"Kent",
	"Kincardineshire",
	"Kirkcudbrightshire",
	"Lanarkshire",
	"Lancashire",
	"Leicestershire",
	"Lincolnshire",
	"Londonderry",
	"Merionethshire",
	"Middlesex",
	"Midlothian",
	"Monmouthshire",
	"Montgomeryshire",
	"Morayshire",
	"Nairnshire",
	"Norfolk",
	"Northamptonshire",
	"Northumberland",
	"Nottinghamshire",
	"Orkney",
	"Oxfordshire",
	"Peeblesshire",
	"Pembrokeshire",
	"Perthshire",
	"Radnorshire",
	"Renfrewshire",
	"Ross-shire",
	"Roxburghshire",
	"Rutland",
	"Selkirkshire",
	"Shetland",
	"Shropshire",
	"Somerset",
	"Staffordshire",
	"Stirlingshire",
	"Suffolk",
	"Surrey",
	"Sussex",
	"Sutherland",
	"Tyrone",
	"Warwickshire",
	"West Lothian (Linlithgowshire)",
	"Westmorland",
	"Wigtownshire",
	"Wiltshire",
	"Worcestershire",
	"Yorkshire"
];
