<template>
	<!-- <ul class=" nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start" id="menu"> -->
	<template v-for="item in $props.state.mainMenu" :key="JSON.stringify(item)">
		<li v-if="item.hasOwnProperty('children') && item.children.length > 0 && isEnabled(item, $props.type)" class="nav-item">
			<button type="button" class="transition duration-75 group w-full d-flex items-center p-2 lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800" :class="isActive(item) ? 'bg-theme-800' : ''" @click.prevent="state.currentExpandedMenuItem ? state.currentExpandedMenuItem = null : state.currentExpandedMenuItem = item">
				<Icon :name="item.icon" class="mr-2 fs-4 pl-2 -mt-1"/>
				<span class="flex-1 text-left" v-html="item.name"/>
				<Icon :name="JSON.stringify(state.currentExpandedMenuItem) === JSON.stringify(item) ? 'angle-up' : 'angle-down'" class="mr-2 fs-4 pl-2 -mt-1"/>
			</button>
			<ul id="dropdown-example" class="py-2 space-y-2" :class="JSON.stringify(state.currentExpandedMenuItem) === JSON.stringify(item) ? '' : 'hidden'">
				<template v-for="subitem in item.children" :key="JSON.stringify(subitem)">
					<template v-if="isEnabled(subitem, $props.type)">
						<li v-if="subitem.hasOwnProperty('onClick')">
							<router-link :to="subitem.to ? subitem.to : '#'" class="d-flex items-center pygita-2 pl-11 w-full lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800" :class="isActive(subitem) ? 'bg-theme-800' : ''" @click.prevent="subitem.onClick">
								{{ subitem.name }}
								<span class="sr-only" v-html="subitem.name"/>
							</router-link>
						</li>
						<li v-else>
							<router-link :to="subitem.to ? subitem.to : '#'" class="d-flex items-center py-2 pl-11 w-full lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800" :class="isActive(subitem) ? 'bg-theme-800' : ''">
								{{ subitem.name }}
								<span class="sr-only" v-html="subitem.name"/>
							</router-link>
						</li>
					</template>
				</template>
			</ul>
		</li>
		<li v-else-if="isEnabled(item, $props.type)" class="nav-item w-100 ">
			<a v-if="item.hasOwnProperty('onClick') && !item.to" href="#" class="nav-link lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800" @click.prevent="item.onClick">
				<Icon :name="item.icon" class="mr-2 fs-4 pl-2 -mt-1"/>
				<span class="d-none d-sm-inline px-2" v-html="item.name"/>
				<span v-if="item.hasOwnProperty('label') && item.label" class="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-circle dark:bg-gray-700 dark:text-gray-300" v-html="item.label"/>
			</a>
			<router-link v-else :to="item.to ? item.to : '#'" class="nav-link lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800" :class="isActive(item) ? 'bg-theme-800' : ''">
				<Icon :name="item.icon" class="mr-2 fs-4 pl-2 -mt-1"/>
				<span class="d-none d-sm-inline px-2" v-html="item.name"/>
				<span v-if="item.hasOwnProperty('label') && item.label" class="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-circle dark:bg-gray-700 dark:text-gray-300" v-html="item.label"/>
			</router-link>
		</li>
	</template>
	<template v-if="state.footerLeftLink">
		<li class="nav-item">
			<a v-if="state.footerLeftLink.href" :href="state.footerLeftLink.href" class="nav-link lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800">
				<Icon :name="state.footerLeftLink.icon" class="mr-3"/>
				<span class="d-none d-sm-inline px-2">{{ state.footerLeftLink.name }}</span>
			</a>
			<router-link v-else :to="state.footerLeftLink.to" clas="nav-link lh-base fw-normal text-white fw-semibold rounded-3 hover:text-theme-300 hover-bg-theme-800 dark:hover-bg-theme-800">
				<Icon :name="state.footerLeftLink.icon" class="mr-3"/>
				<span class="d-none d-sm-inline px-2">{{ state.footerLeftLink.name }}</span>
			</router-link>
		</li>
	</template>
	<!-- </ul> -->
</template>

<script>

	import { defineComponent } from "vue";
	import { useRouter } from "vue-router";
	import { useAuthStore } from "@/stores";
	import Icon from "@/views/components/icons/Icon";

	export default defineComponent({
		name: "WcaMenu",
		components: {
			Icon
		},
		props: {
			state: {
				type: Object,
				default: () => { return {}; }
			},
			type: {
				type: String,
				default: ""
			}
		},
		setup (props) {

			const router = useRouter();
			const authStore = useAuthStore();

			function isActive (obj) {

				let currentPath = router.currentRoute.value.path;
				let isActiveMainItem = obj.to === currentPath;
				let isActiveSubItem = false;
				if (Object.prototype.hasOwnProperty.call(obj, "children")) {
					for (let i in obj.children) {
						if (obj.children[i].to === currentPath) {
							isActiveSubItem = true;
							break;
						}
					}
				}
				return isActiveMainItem || isActiveSubItem;
			}

			function isEnabled (obj, type) {

				if (!obj) {
					return false;
				}

				let hasPermission = Object.prototype.hasOwnProperty.call(obj, "requiresAbility") && false !== obj.requiresAbility ? authStore.hasAbilities(obj.requiresAbility) : true;

				let totalEnabledSubItems = 0;
				if (Object.prototype.hasOwnProperty.call(obj, "children")) {
					for (let i in obj.children) {
						if (isEnabled(obj.children[i], type)) {
							totalEnabledSubItems++;
						}
					}
				} else {
					totalEnabledSubItems = 1;
				}

				if (type === "desktop") {
					return hasPermission && obj.showDesktop && totalEnabledSubItems > 0;
				} else if (type === "mobile") {
					return hasPermission && obj.showMobile && totalEnabledSubItems > 0;
				}
				return false;
			}

			return {
				isEnabled,
				isActive
			};
		}
	});
</script>
