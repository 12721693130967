<template>
	<Modal
		:id="elementId" :title="trans('users.labels.update_site')" :has-form="true" @submit="onFormSubmit"
		@clear="onFormClear" @close="onFormClear">
		<UiForm
			:classes="'needs-validation'" :type="'update'" :data-bs-dismiss="formValidated" @submit="onFormSubmit()"
			@clear="onFormClear" @close="onFormClear">
			<TextInput
				v-model="form.name" type="text" :required="true"
				:label="'Woodland or Site ' + trans('users.labels.name')" name="name" class="mb-2" />
			<TextInput
				v-model="form.gridref" type="text" :title="'6-figure grid reference'"
				:pattern="'[A-Za-z]{2}\d{6}'" :required="true" :label="trans('users.labels.gridref')" name="name"
				class="mb-2" />{{ trans('users.labels.gridref_sub') }}
			<div v-if="!validateGridRef_6()">
				Please enter a valid format (2 letters + 6 numbers, no spaces).
			</div>
			<br>
			<small>{{ trans('users.labels.gridref_sub') }} Use <a
				href="https://magic.defra.gov.uk/MagicMap.aspx">MAGIC's</a> locator tool if you are unsure.
				MAGIC will generate an eight digit grid reference. To convert to a six figure grid reference please
				delete the 4th and 8th digit e.g. 123<span style="color:red">4</span>567<span style="color:red">8</span>
				would
				give a grid reference of 123567
			</small>
			<br>
			{{ trans('global.phrases.select_county') }}:
			<select v-model="form.county" class="form-select" :required="false" aria-label="Select County">
				<option :value="null" />
				<option v-for="(char, index) in countiesList" :key="index" :value="char">
					{{ char }}
				</option>
			</select>
			<hr>
			<TextInput
				v-model="form.single_business_id" type="text" :required="false"
				:label="trans('users.labels.single_business_id')+' ('+trans('global.phrases.optional')+')'" name="name"
				class="mb-2" />
			<TextInput
				v-model="form.higher_tier_ref" type="text" :required="false"
				:label="trans('users.labels.higher_tier_ref')+' ('+trans('global.phrases.optional')+')'" name="name"
				class="mb-2" />
			<TextInput
				v-model="form.woodland_man_plan" type="text" :required="false"
				:label="trans('users.labels.woodland_man_plan')+' ('+trans('global.phrases.optional')+')'" name="name"
				class="mb-2" />
		</UiForm>
	</modal>
</template>

<script lang="ts" setup>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted, computed, watch } from "vue";
	// import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	// import { useAlertStore } from "@/stores";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import { useSiteStore, countiesList } from "@/stores/site";

	const authService = new AuthService();
	// const alertStore = useAlertStore();
	const authStore = useAuthStore();
	const siteStore = useSiteStore();

	const form = reactive({
		name: "",
		gridref: "",
		single_business_id: "",
		higher_tier_ref: "",
		woodland_man_plan: "",
		county: ""
	});

	const props = defineProps<{
		site: any;
		elementId:string;
	}>();

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		onFormClear();
	});

	async function onFormClear () {
		form.name = props.site.name || "NONE";
		form.gridref = props.site.gridref;
		form.single_business_id = props.site.single_business_id || "NONE";
		form.higher_tier_ref = props.site.higher_tier_ref || "NONE";
		form.woodland_man_plan = props.site.woodland_man_plan || "NONE";
		form.county = props.site.county;

	}

	function validateGridRef_6 () {
		if (form.gridref.length > 8) return false;
		if (form.gridref.includes(" ")) return false;
		if (form.gridref.includes("-")) return false;
		// Regular expression for 2 letters followed by 6 numbers
		// const pattern = /^((([sS]|[nN])[a-hA-Hj-zJ-Z])|(([tT]|[oO])[abfglmqrvwABFGLMQRVW])|([hH][l-zL-Z])|([jJ][lmqrvwLMQRVW]))\s?([0-9]{3})\s?([0-9]{3})$/;
		const pattern = /^[A-Za-z]{2}\d{6}$/;
		// Test if the input matches the pattern
		return pattern.test(form.gridref);
	}

	function validateForm () {
		return (!(form.name == "") && validateGridRef_6());
	}
	const formValidated = computed (() => {
		return validateForm();
	});

	watch(() => props.site, (newVal) => {
		onFormClear();
	});
	async function onFormSubmit () {
		if (form.gridref.includes(" ")) form.gridref = form.gridref.replace(/\s/g, "");
		if (form.gridref.length > 8) form.gridref = form.gridref.substring(0, 8);
		if (formValidated.value) {
			props.site.name = form.name;
			props.site.gridref = form.gridref;
			props.site.single_business_id = form.single_business_id;
			props.site.higher_tier_ref = form.higher_tier_ref;
			props.site.woodland_man_plan = form.woodland_man_plan;
			props.site.county = form.county;
			props.site.updated_at = new Date().toISOString().replace("T", " ");
			await siteStore.update(props.site);
			onFormClear();
		}
	}

</script>
