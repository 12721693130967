<template>
	<Panel :title="trans('users.labels.general_settings')">
		<form @submit.prevent="onFormSubmit">
			<TextInput v-model="form.first_name" type="text" :required="true" :label="trans('users.labels.first_name')" name="first_name" class="mb-4"/>
			<TextInput v-model="form.last_name" type="text" :required="true" :label="trans('users.labels.last_name')" name="last_name" class="mb-4"/>
			<TextInput v-model="form.email" type="email" :required="true" :label="trans('users.labels.email')" name="email" autocomplete="email" class="mb-4"/>
			<UiButton type="submit" :label="trans('global.buttons.update')" @click="onFormSubmit"/>
		</form>
	</Panel>
</template>

<script>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { useAlertStore } from "@/stores";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput";
	import Panel from "@/views/components/Panel";

	export default defineComponent({
		components: {
			Panel,
			UiButton,
			TextInput
		},

		setup: function () {

			const authService = new AuthService();
			const alertStore = useAlertStore();
			const authStore = useAuthStore();
			const form = reactive({
				first_name: null,
				last_name: null,
				middle_name: null,
				email: null
			});

			onMounted(() => {
				if (!authStore.user) {
					return;
				}
				form.first_name = authStore.user.first_name;
				form.last_name = authStore.user.last_name;
				form.middle_name = authStore.user.middle_name;
				form.email = authStore.user.email;
			});

			function onFormSubmit () {
				authService.updateUser(form)
					.then(() => authStore.getCurrentUser())
					.then((response) => (alertStore.success(trans("global.phrases.profile_updated"))))
					.catch((error) => (alertStore.error(getResponseError(error))));
			}

			return {
				onFormSubmit,
				form,
				trans
			};
		}
	});
</script>
