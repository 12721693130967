/* eslint-disable no-mixed-spaces-and-tabs */

export const preAssessmentSurveyJson =
{
	"title": "Answer these questions before you start the woodland walk.",
	"description": "These questions are best answered using GIS tools which may be easier accessed prior to commencing a site visit.",
	"logoPosition": "right",
	"pages": [

		{
			"name": "page0a",
			"elements": [
			 {
						  "type": "radiogroup",
						  "name": "survey_type",
						  "title": "Survey type (entered when the survey was created):",
						  "readOnly": true,
						  "choices": [
			   {
							"value": "wca",
							"text": "Woodland Condition Assessment"
			   },
			   {
							"value": "bng",
							"text": "Biodiversity Net Gain"
			   }
						  ]
			 },
			 {
						  "type": "dropdown",
						  "name": "habitat_type",
						  "visibleIf": "{survey_type} = 'bng'",
						  "readOnly": true,
						  "title": "This survey applies to the following habitat (entered when the survey was created):",
						  "choices": [
			   {
							"value": "0",
							"text": "w1a - Upland oakwood"
			   },
			   {
							"value": "1",
							"text": "w1b - Upland mixed ashwoods"
			   },
			   {
							"value": "2",
							"text": "w1c - Lowland beech and yew woodland"
			   },
			   {
							"value": "3",
							"text": "w1d - Wet woodland"
			   },
			   {
							"value": "4",
							"text": "w1e - Upland birchwoods"
			   },
			   {
							"value": "5",
							"text": "w1f - Lowland mixed deciduous woodland"
			   },
			   {
							"value": "6",
							"text": "w1g - Other woodland; broadleaved"
			   },
			   {
							"value": "7",
							"text": "w1h - Other woodland; mixed"
			   },
			   {
							"value": "8",
							"text": "w2a - Native pine woodlands"
			   },
			   {
							"value": "9",
							"text": "w2b - Other Scots pine woodland"
			   },
			   {
							"value": "10",
							"text": "w2c - Other coniferous woodland"
			   }
						  ]
			 }
			],
			"title": "Survey type",
			"visible": false
		   },
	 {
	  "name": "page5",
	  "visible": true,
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question23",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">" +
						"<div class=\"accordion-item\">" +
						"<h2 class=\"accordion-header\" id=\"flush-headingOne\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Open space within woodland</button></h2>" +
						"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>Use maps and aerial photographs to estimate what % of Woodland area is open space. </p>" +
						"<p>This is <em>temporary open space</em> in which trees can be expected to regenerate (e.g. glades, rides, footpaths, areas " +
							"of clear-fell). This differs from <em>permanent open space</em> where tree regeneration is not possible or desirable (e.g. " +
							"tarmac, buildings, rivers). The area of open space must be at least 10m wide with less than 20% covered by shrubs or trees.</p>" +
					// "<p>In order " +
					// "to reach good condition, transitions between areas of more open space and more closed canopy woodland " +
					// "should be graded ecotones, with transitions not clearly delineated. Exemptions for small woodlands in indicator " +
					// "description are due to the increased ratio of edge habitat to woodland where the woodland is <10ha.</p>" +
						"<p>You will be asked to verify this answer after the woodland walk.</p>" +
						"<p>You may find the CanopyCapture application (<a href=\"https://play.google.com/store/apps/details?id=com.nikhilpatel.canopycapture&pli=1\" target=\"_blank\">Android</a>, <a href=\"https://appsonwindows.com/apk/5197531/\" target=\"_blank\">Windows</a>) useful for this question.</p>" +
						"</div>\n</div>\n</div>\n</div>"
	   },
	   {
					"type": "nouislider",
					"name": "question6",
					"isRequired": true,
					"title": "What percentage of the woodland (or woodland parcel) is open space?",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"inputType": "range",
					"min": 0,
					"max": 100,
					"step": 1
	   }
	  ],
	  "title": "6|F Open space within woodland"
	 },
	 {
	  "name": "page6",
	  "visible": true,
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question24",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\"><div class=\"accordion-item\">" +
						"<h2 class=\"accordion-header\" id=\"flush-headingOne\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Proporton of favourable land cover</button></h2>" +
						"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p><strong>How to work out the % proporton of favourable land cover around your woodland</strong></p>" +
						"<p>Use <a href=\"https://magic.defra.gov.uk/\" target=\"_blank\">magic.gov.uk</a> to estimate what % of land cover in a 5.6km radius of the woodland is favourable.</p>" +
						"<ol><li class=\"py-2\">Find your woodland</li>" +
						"<li class=\"py-2\">Click on Site Check</li>" +
						"<li class=\"py-2\">Define area of interest using choice of butons</li>" +
						"<li class=\"py-2\">Type in 5,600 metres in Buffer Shapes</li>" +
						"<li class=\"py-2\">Click on Habitats and Species</li>" +
						"<img class=\"w-100\" src=\"/WCA_Step_7_How_to_work_out_the_proportion_of_favorable_land_cover_around_your_woodland.png\"/></li>" +
						"<li class=\"py-2\">Tick" +
						"	<ul><li>Grassland and tick all sub categories of grassland</li><li>Heathland and tick uppermost three sub categories (lowland, mountain & upland)</li><li>Inland Rock and tick Limestone pavement.</li><li>Wetland and tick uppermost five sub categories i.e. bog, fen, flushes, reedbeds</li><li>Woodland and tick priority Habitat Inventories for deciduous woodland, orchards, wood pasture and parkland.</li></ul>" +
						"</li>" +
						"<li class=\"py-2\">Click on Perform Site Check</li>" +
						"<img class=\"w-100\" src=\"/WCA_Step_7_How_to_work_out_the_proportion_of_favorable_land_cover_around_your_woodland_2.png\"/></li>" +
						"<li class=\"py-2\">Click on Export to CSV. Use sum in excel to add up all the area figures in column H and divide" +
						"by 100 to give you the % proporton of favourable land cover around your woodland." +
						"</ol>" +
						"</div></div></div></div>"
	   },
	   {
					"type": "nouislider",
					"isRequired": true,
					"name": "question7",
					"title": "How much (%) favourable land cover is there within 5.6km of the woodland boundary (including the woodland area itself)?",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"inputType": "range",
					"min": 0,
					"max": 100,
					"step": 1
	   }
	  ],
	  "visibleIf": "{survey_type} = 'wca'",
	  "title": "7 Proportion of favourable land cover"
	 },
	 {
	  "name": "page11",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question30",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">\n  <div class=\"accordion-item\"><h2 class=\"accordion-header\" id=\"flush-headingOne\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Size of woodland</button></h2><div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\">\n\n<div class=\"accordion-body\">" +
					"<p>There is an established relationship between species richness and habitat area  which is particularly well-documented for more specialist species: as habitat parcel size increases so does the area to perimeter ratio, resulting in proportionally more of the internal woodland environment that is important to some species  and proportionally less edge habitat that can be detrimental to some species.</p>\n\n<p>For woodland biodiversity, there is evidence that woodland parcels less than 3-5ha in size are less able to support some woodland taxa compared to larger woodlands, although different woodland species require different minimum woodland areas.</p></div> </div></div></div>"
	   },
	   {
					"type": "text",
					"isRequired": true,
					"name": "question14a",
					"title": "Total area (ha):",
					"defaultValueExpression": "",
					"inputType": "number",
					"minValueExpression": "0",
					"step": 0.1
	   },
	   {
					"type": "text",
					"isRequired": true,
					"name": "question14b",
					"title": "Total broadleaved, mixed and yew woodland area (ha):",
					"defaultValueExpression": "",
					"inputType": "number",
					"minValueExpression": "0",
					"maxValueExpression": "{question14a} - {question14c}",
					"visibleIf": "{survey_type} = 'wca'",
					"step": 0.1
	   },
	   {
					"type": "text",
					"isRequired": true,
					"name": "question14c",
					"title": "Total coniferous woodland area (ha):",
					"defaultValueExpression": "",
					"inputType": "number",
					"minValueExpression": "0",
					"maxValueExpression": "{question14a}-{question14b}",
					"visibleIf": "{survey_type} = 'wca'",
					"step": 0.1
	   }
	  ],
	  "title": "14 Size of woodland (or parcel)"
	 },
	 {
	  "name": "notespage",
	  "visible": false,
	  "elements": [
	   {
					"type": "comment",
					"isRequired": false,
					"name": "survey_notes",
					"title": "Please use this section to add any further survey notes for this assessment:"
	   }
	  ],
	  "title": "Notes"
	 }
	],
	"calculatedValues": [
	 {
	  "name": "q6",
	  "expression": "iif({question6}==-5,0,iif({question6}<10,1,iif({question6}<20,3, iif({question6}<40,2,1))))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q7",
	  "expression": "iif({question7}==-5,0,iif({question7}>20,3, iif({question7}>=10,2,1)))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q14",
	  "expression": "iif({question14a}>=20,3,iif({question14a}>=5,2,iif({question14a}>0,1,0)))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q14_broadleaf",
	  "expression": "{question14b}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q14_conifer",
	  "expression": "{question14c}",
	  "includeIntoResult": true
	 }
	],
	"sendResultOnPageNext": true,
	"showProgressBar": "bottom",
	// "showCompletedPage": true,
	"showTitle": false,
	"checkErrorsMode": "onComplete",
	"pageNextText": "Save & continue",
	"completeText": "Save",
	"showPreviewBeforeComplete": "showAllQuestions",
	"widthMode": "responsive",
	"fitToContainer": true,
	"showQuestionNumbers": "off",
	// "showNavigationButtons": "bottom",
	"previewText": "Save & preview",
	"completedHtml": "Your woodland survey is complete. Until you have submitted your survey, you will still be able to edit these answers. Simply return to this page or refresh.",
	"showCompletedPage": false,
	"showPrevButton": false,
	"showNavigationButtons": "none"
};

